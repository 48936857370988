import { css } from '@emotion/react'
import type { JobDescription, QueueDescription } from '@orus.eu/backend/src/routers/jobs'
import {
  Button,
  ContentContainerBackoffice,
  LegacyDialog,
  Text,
  colorTokens,
  spacing,
  useAsyncCallback,
  useDialogVisibility,
} from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { memo } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { BackofficeSubsectionTitle } from '../../../atoms/backoffice-subsection-title'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'

const PlatformAdminJobsPage = memo(function PlatformAdminJobsPage() {
  const navigateToBackofficeAdmin = useNavigateTo({ to: '/bak/admin' })

  const queues = trpcReact.jobs.listQueuesStatuses.useQuery()
  if (!queues.data) return <GlobalLoadingState />
  return (
    <ContentContainerBackoffice marginTop={spacing[70]}>
      <Header
        title="Jobs admin page"
        leftButton={
          <Button icon="arrow-left-regular" size="small" variant="secondary" onClick={navigateToBackofficeAdmin} />
        }
      />
      <div
        css={css`
          margin-top: ${spacing[50]};
        `}
      >
        <TestButtons />
      </div>

      {queues.data.map((queue) => (
        <QueueBlock key={queue.name} queue={queue} />
      ))}
    </ContentContainerBackoffice>
  )
})
export default PlatformAdminJobsPage

type QueueBlockProps = {
  queue: QueueDescription
}
const QueueBlock = memo<QueueBlockProps>(function QueueBlock({ queue }) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[40]};
      `}
    >
      <BackofficeSubsectionTitle>{queue.name}</BackofficeSubsectionTitle>
      <JobsList
        title="Jobs crashés"
        explanation="Ces jobs ont eu un problème technique qui ont empêché la planification du retry"
        queueName={queue.name}
        jobs={queue.crashedJobs}
      />
      <JobsList
        title="Jobs en attente de retry"
        explanation="Ces jobs ont échoué une fois ou plus et sont en attente de retry"
        queueName={queue.name}
        jobs={queue.scheduledJobs.filter((job) => job.failedAttempts > 0)}
      />
      <JobsList
        title="Jobs planifiés"
        explanation="Ces jobs sont planifiés à un moment dans le futur"
        queueName={queue.name}
        jobs={queue.scheduledJobs.filter((job) => job.failedAttempts === 0)}
      />
    </div>
  )
})

type JobsListProps = {
  title: string
  explanation: string
  queueName: string
  jobs: JobDescription[]
}
const JobsList = memo<JobsListProps>(function JobsList({ queueName, jobs, title, explanation }) {
  return (
    <div>
      <Text variant="subtitle">{title}</Text>
      <Text variant="caption">{explanation}</Text>
      <ul
        css={css`
          padding: 0;
          margin: 0;

          li {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
        `}
      >
        {jobs.map((job) => (
          <JobListItem key={job.id} queueName={queueName} job={job} />
        ))}
      </ul>
    </div>
  )
})

type JobListItemProps = {
  queueName: string
  job: JobDescription
}
const JobListItem = memo<JobListItemProps>(function JobListItem({ queueName, job }) {
  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    visible: deleteDialogVisible,
  } = useDialogVisibility('delete-job-' + job.id)

  return (
    <li
      css={css`
        display: flex;
        gap: ${spacing[40]};

        position: relative;

        > button {
          display: none;
        }

        &:hover {
          background-color: ${colorTokens['color-bg-base-tertiary']};

          > button {
            display: initial;
          }
        }
      `}
    >
      <code
        css={css`
          white-space: nowrap;
          color: ${colorTokens['color-text-info-primary']};
        `}
      >
        {job.scheduledAt}
      </code>
      <div>
        <code
          css={css`
            white-space: nowrap;
            color: ${colorTokens['color-text-info-secondary']};
          `}
        >
          {job.id}
        </code>
        {job.failedAttempts ? (
          <Text variant="caption" color={colorTokens['color-text-danger-secondary']}>
            Échecs: {job.failedAttempts}
          </Text>
        ) : (
          <></>
        )}
      </div>
      <code
        css={css`
          white-space: pre-wrap;
        `}
      >
        {job.dataDescription}
      </code>
      <Button
        variant="primary"
        style="danger"
        size="small"
        css={css`
          position: absolute;
          top: 0;
          right: 0;
        `}
        onClick={showDeleteDialog}
      >
        Delete
      </Button>
      {deleteDialogVisible ? <DeleteJobDialog queueName={queueName} job={job} onClose={hideDeleteDialog} /> : <></>}
    </li>
  )
})

const TestButtons = memo(function TestButtons() {
  const scheduleSuccess = useAsyncCallback(async () => {
    await trpc.jobs.submitTestJob.mutate({
      delay: 20000,
      data: {
        desiredOutcome: 'success',
        message: prompt('Message ?') ?? '<no message>',
        duration: 1000,
      },
    })
    document.location.reload()
  }, [])

  const scheduleFailure = useAsyncCallback(async () => {
    await trpc.jobs.submitTestJob.mutate({
      delay: 20000,
      data: {
        desiredOutcome: 'failure',
        message: prompt('Message ?') ?? '<no message>',
        duration: 1000,
      },
    })
    document.location.reload()
  }, [])
  return (
    <div
      css={css`
        display: flex;
        gap: ${spacing[40]};
      `}
    >
      <Button icon="check-solid" variant="secondary" size="small" onClick={scheduleSuccess}>
        Tester un job qui réussit
      </Button>
      <Button icon="xmark-solid" variant="secondary" size="small" onClick={scheduleFailure}>
        Tester un job qui échoue
      </Button>
    </div>
  )
})

type DeleteJobDialogProps = {
  queueName: string
  job: JobDescription
  onClose: () => void
}

const DeleteJobDialog = memo<DeleteJobDialogProps>(function DeleteJobDialog({ job, queueName, onClose }) {
  const deleteJob = useAsyncCallback(async () => {
    await trpc.jobs.deleteJob.mutate({ jobId: job.id, workerName: queueName })
    onClose()
    document.location.reload()
  }, [job.id, onClose, queueName])

  return (
    <LegacyDialog
      variant="backoffice"
      fullWidth
      style="danger"
      title="Supprimer le job ?"
      onClose={onClose}
      onSubmit={deleteJob}
      submitLabel="Confirmer"
      secondaryActionLabel="Ne rien faire"
      onSecondaryAction={onClose}
    >
      <Text>Tu t&apos;apprêtes à supprimer définitivement le job suivant. Il va disparaître à tout jamais.</Text>
      <Text>Cette action sera loggée.</Text>
      <div
        css={css`
          display: grid;
          grid-template-columns: 0fr 1fr;
          gap: ${spacing[40]};
          margin-top: ${spacing[50]};
        `}
      >
        <Text>Id</Text>
        <code
          css={css`
            white-space: nowrap;
            color: ${colorTokens['color-text-info-secondary']};
          `}
        >
          {job.id}
        </code>

        <Text>Planification</Text>
        <code
          css={css`
            white-space: nowrap;
            color: ${colorTokens['color-text-info-primary']};
          `}
        >
          {job.scheduledAt}
        </code>

        <Text>Détail</Text>
        <code
          css={css`
            white-space: pre-wrap;
          `}
        >
          {job.dataDescription}
        </code>
      </div>
    </LegacyDialog>
  )
})
